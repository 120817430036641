<template>
   <div class="mask" v-if="show">
      <p class="tip">Under Construction...</p>
    </div>
</template>
<script>
export default {
  data() {
    return {
      show: true
    };
  },
  methods: {
    
  }
};
</script>
<style>
  .mask{
    background-color: gray;
    width: 600px;
    z-index: 1000;
    opacity: 0.3;
    position: absolute;
    height: calc(100% - 64px);
    margin-top: 64px;
    margin-left: 1300px;
  }
  .tip{
      padding: 10px;
      background-color: white;
      position: absolute;
      top: 50%;
      left: 50%;
      transform:translate(-50%,-50%);
      font-size: 50px;
  }
</style>