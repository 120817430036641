<template>
  <div class="page flex-col">
    <div class="group1 flex-col">
      <div class="box1 flex-row">
        
        <img
          class="img1"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng61ffb35a24cb841dce8f02fa2253f1315f3b29a94031ad9e9976da0a279290f2.png"
        />
        <span class="txt1 pointer" @click="toHomepage">Homepage</span>
        <span class="word1 pointer" @click="toDiagnosis">Diagnosis</span>
        <!--        <span class="word2 pointer" @click="toDependencyNetwork">Dependency&nbsp;Network</span>-->
        <span class="info1 pointer" @click="toIssueReport">Issue&nbsp;reports</span>
        <span class="txt2 pointer" @click="toAbout">About</span>
        <span class="word2"></span><!--给 Dependency 占位用的-->
        <div class="wrap1 flex-col">
          <img
            class="icon1"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPnge978d5d56bd5fc93e61ea434f1d203fdc0e159e7885af3808d0af0703f8f3759.png"
          />
        </div>
      </div>
      <span class="info2 pointer" @click="toshow">Reproduction&nbsp;package</span>
      <selects :isShow="isShow"></selects>
    </div>
    <div class="group2 flex-col">
      <div class="layer1 flex-row"><span class="word3">How&nbsp;Insight&nbsp;works</span></div>
      <div class="layer2 flex-row">
        <div class="box2 flex-col">
          <div class="layer3 flex-col justify-between">
            <div class="block1 flex-col"><span class="word4">C&nbsp;Library&nbsp;Extraction</span></div>
            <span class="infoBox1">
              Taking&nbsp;archives&nbsp;and&nbsp;source&nbsp;code&nbsp;files&nbsp;of&nbsp;PyPI&nbsp;and&nbsp;Maven&nbsp;projects&nbsp;as&nbsp;inputs.
              <br />
              Basing&nbsp;on&nbsp;the&nbsp;C&nbsp;library&nbsp;naming&nbsp;rules&nbsp;to&nbsp;extract&nbsp;C&nbsp;libraries&nbsp;from&nbsp;these&nbsp;archives.
            </span>
          </div>
        </div>
        <div class="box3 flex-col">
          <div class="layer4 flex-col justify-between">
            <div class="layer5 flex-col"><span class="txt3">C&nbsp;Project&nbsp;Version&nbsp;Tracing</span></div>
            <span class="paragraph1">
              Extracting&nbsp;characterization&nbsp;feature&nbsp;of&nbsp;C&nbsp;library&nbsp;versions.
              <br />
              Constructing&nbsp;knowledge&nbsp;repository&nbsp;for&nbsp;C&nbsp;libraries.
              <br />
              Determining&nbsp;project&nbsp;version&nbsp;by&nbsp;feature&nbsp;similarity.
            </span>
          </div>
        </div>
        <div class="box4 flex-col">
          <div class="section1 flex-col justify-between">
            <div class="mod1 flex-col"><span class="word5">Vulnerable&nbsp;C-API&nbsp;Analysis</span></div>
            <span class="infoBox2">
              Constructing&nbsp;Vulnerability&nbsp;Database.
              <br />
              Analyzing&nbsp;Cross-language&nbsp;Invocations.
            </span>
          </div>
        </div>
      </div>
      <div class="layer6 flex-row justify-between">
        <img
          class="pic1"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPngbeb2d4ce7ba0cb3a06b5d9b1d36e4cbcb60b5a1a719c8f72ef448f11a13ef89e.png"
        />
        <span class="word6">Knowledge Base</span>
        <img
          class="img2"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPngbeb2d4ce7ba0cb3a06b5d9b1d36e4cbcb60b5a1a719c8f72ef448f11a13ef89e.png"
        />
      </div>
      <div class="layer7 flex-row">
        <div class="main1 flex-col">
          <div class="main2 flex-col justify-between">
            <div class="main3 flex-col">
              <span class="word7">Projects&nbsp;in&nbsp;PyPI&nbsp;and&nbsp;Maven&nbsp;ecosystem</span>
            </div>
            <span class="word8">
              Our&nbsp;Dependency&nbsp;Network&nbsp;consists&nbsp;of&nbsp;417,416(356,718)&nbsp;projects’&nbsp;metadata&nbsp;of&nbsp;PyPI(Maven),&nbsp;involving&nbsp;3,383,391(5,707,274)&nbsp;project&nbsp;versions&nbsp;and&nbsp;12,382,505(28,945,967)&nbsp;dependency&nbsp;relationships.&nbsp;We&nbsp;keep&nbsp;automatically&nbsp;update&nbsp;each&nbsp;dependency&nbsp;network&nbsp;from&nbsp;Libraries.io&nbsp;on&nbsp;a&nbsp;week&nbsp;basis.
            </span>
          </div>
        </div>
        <div class="main4 flex-col">
          <div class="layer8 flex-col justify-between">
            <div class="layer9 flex-col">
              <span class="txt4">Knowledge&nbsp;Repository&nbsp;for&nbsp;C&nbsp;Libraries</span>
            </div>
            <span class="info3">
              Our&nbsp;knowledge&nbsp;repository&nbsp;consists&nbsp;of&nbsp;metadata&nbsp;of&nbsp;485,021&nbsp;C&nbsp;project&nbsp;versions,&nbsp;involving&nbsp;829,242&nbsp;C&nbsp;libraries,&nbsp;from&nbsp;three&nbsp;mainstream&nbsp;Linux&nbsp;distributions:&nbsp;Ubuntu,&nbsp;Debian&nbsp;and&nbsp;Arch&nbsp;Linux.&nbsp;For&nbsp;each&nbsp;C&nbsp;library,&nbsp;we&nbsp;construct&nbsp;a&nbsp;unique&nbsp;feature&nbsp;vector&nbsp;as&nbsp;the&nbsp;identification&nbsp;of&nbsp;its&nbsp;precise&nbsp;version.&nbsp;We&nbsp;keep&nbsp;automatically&nbsp;update&nbsp;knowledge&nbsp;repository&nbsp;when&nbsp;these&nbsp;three&nbsp;Linux&nbsp;distributions&nbsp;release&nbsp;new&nbsp;versions.
            </span>
          </div>
        </div>
        <div class="main5 flex-col">
          <div class="outer1 flex-col justify-between">
            <div class="bd1 flex-col"><span class="word9">Vulnerability&nbsp;Database</span></div>
            <span class="infoBox3">
              Our&nbsp;vulnerability&nbsp;database&nbsp;consists&nbsp;of&nbsp;45,473
              <!--<countTo style="color:#ff0000" :startVal='startVal' :endVal='endVal' :duration='3000'></countTo>-->vulnerability&nbsp;reports&nbsp;collected&nbsp;from&nbsp;official&nbsp;security&nbsp;advisory&nbsp;websites:&nbsp;Ubuntu&nbsp;Security,&nbsp;Debian&nbsp;Security,&nbsp;and&nbsp;Arch&nbsp;Linux&nbsp;Security.&nbsp;&nbsp;
              We&nbsp;collected&nbsp;vulnerable&nbsp;APIs&nbsp;corresponding&nbsp;to&nbsp;21,384&nbsp;vulnerability&nbsp;reports.&nbsp;
              We&nbsp;keep&nbsp;automatically&nbsp;update&nbsp;vulnerability&nbsp;database&nbsp;from&nbsp;these&nbsp;three&nbsp;security&nbsp;advisory&nbsp;websites&nbsp;on&nbsp;a&nbsp;week&nbsp;basis.&nbsp;
              <br />
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import selects from '../../template/selects.vue'
export default {
  components:{selects },
  data() {
    return {
    };
  },
 methods: {
     toshow(){
      this.isShow=!this.isShow
      console.log(1);
    },
    toHomepage(){
      this.$router.push({path:'/homepage'})
    },
    toDiagnosis(){
      this.$router.push({path:'/diagnosis'})
    },
    toDependencyNetwork(){
      this.$router.push({path:'/dependencynetwork'})
    },
    toIssueReport(){
      this.$router.push({path:'/issuereports'})
    },
    toAbout(){
      this.$router.push({path:'/about'})
    }
  }
};
</script>
<style scoped lang="css" src="./assets/index.css" />