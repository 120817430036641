<template>
  <div class="page flex-col">
    <div class="main1 flex-col justify-between">
      <div class="main2 flex-col">
        <div class="wrap1 flex-row">
          <img
            class="img1"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng61ffb35a24cb841dce8f02fa2253f1315f3b29a94031ad9e9976da0a279290f2.png"
          />
          <span class="info1 pointer" @click="toHomepage">Homepage</span>
          <span class="txt1 pointer" @click="toDiagnosis">Diagnosis</span>
<!--          <span class="info2 pointer" @click="toDependencyNetwork">Dependency&nbsp;Network</span>-->
          <span class="word1 pointer" @click="toIssueReport">Issue&nbsp;reports</span>
          <span class="word2 pointer" @click="toAbout">About</span>
          <span class="info2"></span><!--给 Dependency 占位用的-->

          <div class="mod1 flex-col">
            <img
              class="label1"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPnge978d5d56bd5fc93e61ea434f1d203fdc0e159e7885af3808d0af0703f8f3759.png"
            />
          </div>
        </div>
        <span class="info3 pointer" @click="toshow">Reproduction&nbsp;package</span>
        
      </div>
      <div class="main3 flex-row justify-between">
        <div class="main4 flex-col">
          <div class="group1 flex-col justify-between">
            <div class="wrap2 flex-col">
              <div class="section1 flex-row justify-between">
                <span class="word3">PyPI&nbsp;Dependency&nbsp;Netwo</span>
                <div class="box1 flex-col"></div>
              </div>
            </div>
            <span class="txt2">Maven&nbsp;Dependency&nbsp;Netw</span>
          </div>
        </div>
        <div class="main5 flex-col">
          <div class="main6 flex-col justify-between">
            <div class="box2 flex-col"></div>
            <!-- <span class="word4">暂&nbsp;&nbsp;无&nbsp;&nbsp;数&nbsp;&nbsp;据</span> -->
            <span class="word4"></span>
          </div>
        </div>
      </div>
    </div>
    <masklayer></masklayer>
    <selects :isShow="isShow"></selects>
  </div>
</template>
<script>
import masklayer from '../../template/mask'
import selects from '../../template/selects.vue'
export default {
  components:{masklayer,selects},
  data() {
    return {
      isShow:false
    };
  },
  methods: {
     toshow(){
      this.isShow=!this.isShow
      console.log(1);
    },
    toHomepage(){
      this.$router.push({path:'/homepage'})
    },
    toDiagnosis(){
      this.$router.push({path:'/diagnosis'})
    },
    toDependencyNetwork(){
      this.$router.push({path:'/dependencynetwork'})
    },
    toIssueReport(){
      this.$router.push({path:'/issuereports'})
    },
    toAbout(){
      this.$router.push({path:'/about'})
    }
  }
};
</script>
<style scoped lang="css" src="./assets/index.css" />