<template>
   <div v-if="isShow">
      <div class="loader">
        <div class="loader-inner">
          <div class="loader-line-wrap">
            <div class="loader-line"></div>
          </div>
          <div class="loader-line-wrap">
            <div class="loader-line"></div>
          </div>
          <div class="loader-line-wrap">
            <div class="loader-line"></div>
          </div>
          <div class="loader-line-wrap">
            <div class="loader-line"></div>
          </div>
          <div class="loader-line-wrap">
            <div class="loader-line"></div>
          </div>
        </div>
        <p class="loading-tips" v-if="tipsShow">{{ loading_tips }}</p>
        <p class="loading-tips" v-if="step1">1.Uploading Files....</p>
        <p class="loading-tips" v-else-if="step2">2.Generate Dependency Graph....</p>
        <p class="loading-tips" v-else-if="step3">3.Identify Vulnerable C Libraries....</p>
        <p class="loading-tips" v-else-if="step4">4.Analyze Vulnerable C-APIs....</p>
      </div>
    </div>
</template>
<script>
import axios from "axios";

export default {
  data() {
    return {
      isShow:false,
      tipsShow:true,
      step1:false,
      step2:false,
      step3:false,
      step4:false,
      timer: '',
      loading_tips: '',
    };
  },
  created(){
     console.log("created!!!")
    //this.showTips()
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    getState(){
      axios.get('/get_process_state').then(res => {
        //alert(res.data.state);
        this.loading_tips = res.data.state;
        if(res.data.state == "0.完成"){
          clearInterval(this.timer);
          this.$emit("endLoadingEvent")
        }
      })
    },
    showTips(){
      axios.get('/process').then(res => {
        this.loading_tips = res.data.msg;
        this.timer = setInterval(this.getState, 2000);
      })
      //var _this=this;
      // setTimeout(()=>{
      //   _this.step1=true;
      //   _this.step2=false;
      //   _this.step3=false;
      //   _this.step4=false;
      // },0)
      // setTimeout(()=>{
      //   _this.step1=false;
      //   _this.step2=true;
      //   _this.step3=false;
      //   _this.step4=false;
      // },2000)
      // setTimeout(()=>{
      //   _this.step1=false;
      //   _this.step2=false;
      //   _this.step3=true;
      //   _this.step4=false;
      // },4000)
      // setTimeout(()=>{
      //   _this.step1=false;
      //   _this.step2=false;
      //   _this.step3=false;
      //   _this.step4=true;
      // },7000)
      // setTimeout(()=>{
      //   //_this.isShow=false
      //   console.log("trigger endLoadingEvent")
      //   this.$emit("endLoadingEvent")
      // },11000)
    }
  }
};
</script>
<style>

  .loading-tips{
      inset: 0px; 
      height: 60px;
      margin: auto;
      position: absolute; 
      width: 700px;
      font-family: HarmonyOS Sans Medium;
      font-size: 40px;
      color: white;
      text-align: center;
      transform: translateY(120px);
  }
  .loader {
    background: #000;
    opacity: 0.3;
    bottom: 0;
    left: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 150px;
    z-index: 99999;
}

.loader-inner {
    bottom: 0;
    height: 60px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 100px;
}

.loader-line-wrap {
    animation: 
		spin 2000ms cubic-bezier(.175, .885, .32, 1.275) infinite
	;
    box-sizing: border-box;
    height: 50px;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    transform-origin: 50% 100%;
    width: 100px;
}
.loader-line {
    border: 4px solid transparent;
    border-radius: 100%;
    box-sizing: border-box;
    height: 100px;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 100px;
}
.loader-line-wrap:nth-child(1) { animation-delay: -50ms; }
.loader-line-wrap:nth-child(2) { animation-delay: -100ms; }
.loader-line-wrap:nth-child(3) { animation-delay: -150ms; }
.loader-line-wrap:nth-child(4) { animation-delay: -200ms; }
.loader-line-wrap:nth-child(5) { animation-delay: -250ms; }

.loader-line-wrap:nth-child(1) .loader-line {
    border-color: hsl(0, 80%, 60%);
    height: 90px;
    width: 90px;
    top: 7px;
}
.loader-line-wrap:nth-child(2) .loader-line {
    border-color: hsl(60, 80%, 60%);
    height: 76px;
    width: 76px;
    top: 14px;
}
.loader-line-wrap:nth-child(3) .loader-line {
    border-color: hsl(120, 80%, 60%);
    height: 62px;
    width: 62px;
    top: 21px;
}
.loader-line-wrap:nth-child(4) .loader-line {
    border-color: hsl(180, 80%, 60%);
    height: 48px;
    width: 48px;
    top: 28px;
}
.loader-line-wrap:nth-child(5) .loader-line {
    border-color: hsl(240, 80%, 60%);
    height: 34px;
    width: 34px;
    top: 35px;
}

@keyframes spin {
    0%, 15% {
		transform: rotate(0);
	}
	100% {
		transform: rotate(360deg);
	}
}
</style>