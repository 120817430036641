import Vue from 'vue'
import VueRouter from 'vue-router'
import diagnosis from '../views/diagnosis/index.vue'
import homepage from '../views/homepage/index.vue'
import issuereports from '../views/issuereports/index.vue'
import diagnosis2 from '../views/diagnosis2/index.vue'
import about from '../views/about/index.vue'
import dependencynetwork from '../views/dependencynetwork/index.vue'

Vue.use(VueRouter)

const routes = [
    {
    path: '/',
    redirect: "/homepage"
  },
  {
    path: '/diagnosis',
    name: 'diagnosis',
    component: diagnosis
  },
  {
    path: '/homepage',
    name: 'homepage',
    component: homepage
  },
  {
    path: '/issuereports',
    name: 'issuereports',
    component: issuereports
  },
  {
    path: '/diagnosis2',
    name: 'diagnosis2',
    component: diagnosis2
  },
  {
    path: '/about',
    name: 'about',
    component: about
  },
  {
    path: '/dependencynetwork',
    name: 'dependencynetwork',
    component: dependencynetwork
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
