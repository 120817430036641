<template>
   <transition name="fade">
        <div class="reproduction_demo flex-row" v-if="isShow"  style="display: block;" >
          <div class="flex-col" style="margin: 2px">
<!--            <iframe class="flex-col" src="//player.bilibili.com/player.html?bvid=BV1ZY4y1G7un&page=1"-->
<!--                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"-->
<!--                    allowfullscreen="" width="1140" height="640" frameborder="0" ></iframe>-->
<!--            -->
            <iframe width="1140" height="640"
                    src="https://www.youtube.com/embed/9M3x7aawrb0" title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen>

            </iframe>
          </div>
        </div>
   </transition>
</template>
<script>
export default {
  data() {
    return {
    };
  },
  props:{
    isShow:Boolean
  },
  methods: {
    
  }
};
</script>
<style>
  .reproduction_demo {
    display: none;
     /*margin-right: 5px;*/
    width: 1144px;
    height: 644px;
     border-radius: 10px;
     /*padding: 10px;*/
     position: absolute;
     left: 390px;
     top: 200px;
     background-color: #2F4056;
     /*float: right;*/
     z-index: 1001;
 }
 .fade-enter-active, .fade-leave-active {
   transition: opacity .5s;
 }
 .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
   opacity: 0;
 }
</style>