<template>
  <div class="page flex-col">
    <div class="section1 flex-col">
      <div class="main1 flex-col">
        <div class="section2 flex-row">
          <div class="main2 flex-col"></div>
          <span class="word1 pointer" @click="toHomepage">Homepage</span>
          <span class="word2 pointer" @click="toDiagnosis">Diagnosis</span>
<!--          <span class="txt1 pointer" @click="toDependencyNetwork">Dependency&nbsp;Network</span>-->
          <span class="txt2 pointer" @click="toIssueReport">Issue&nbsp;reports</span>
          <span class="info1 pointer" @click="toAbout">About</span>
          <span class="txt1"></span><!--给 Dependency 占位用的-->
          <div class="main3 flex-col pointer"><div class="wrap1 flex-col"></div></div>
          <span class="info2 pointer" @click="toshow">Reproduction&nbsp;package</span>
          <selects :isShow="isShow"></selects>
        </div>
      </div>
      <span class="word3">
        Table&nbsp;Statistics&nbsp;of&nbsp;real&nbsp;CLV(cross-language&nbsp;invocations)&nbsp;issues&nbsp;reports&nbsp;by&nbsp;Insight
      </span>
      
      <div class="main4 flex-col">
        <div class="outer1 flex-row justify-between">
          <span class="word4">ID</span>
          <span class="info3">Ecosystem</span>
          <span class="txt3">Project&nbsp;Name</span>
          <span class="txt4">Vulnerable&nbsp;C&nbsp;Libraries</span>
          <span class="word5">#&nbsp;Reported&nbsp;CVE</span>
          <span class="txt5_title">CVE&nbsp;List</span>
          <span class="word6">#&nbsp;Downstream&nbsp;Projects</span>
          <span class="word7">Issue&nbsp;Type</span>
          <span class="word8">Issue&nbsp;Statues</span>
          <span class="wordurl_title">Issue&nbsp;URL</span>
        </div>
      </div>
      <div class="main5 flex-col"></div>
      <!-- <div class="main6 flex-col"><span class="word9">表格文字样式</span></div>-->
      <div class="main7 flex-col"></div>
      <div v-for="(li,index) in defaultData" :key="index">
        <div class=" flex-col main8" >
          <div class="outer1 flex-row justify-between">
            <span class="word4 black">{{li.ID}}</span>
            <span class="info3 black">{{li.Ecosystem}}</span>
            <span class="txt3 black">{{li.Project_Name}}</span>
            <span class="txt4 black">{{li.Vulnerable_C_Libraries}}</span>
            <span class="word5 black">{{li.Reported_CVE}}</span>
            <span class="txt5 black">{{li.CVE_List}}</span>
            <span class="word6 black">{{li.Downstream_Projects}}</span>
            <span class="word7 black">{{li.Issue_Type}}</span>
            <span class="word8 black">{{li.Issue_Statues}}</span>
            <span class="wordurl black"><a :href=li.Issue_URL>{{li.Issue_URL}}</a></span>
          </div>
        </div>

        <div class="main9 flex-col"></div>
      </div>
      <div class="main38 flex-row " v-if="totalPage>1">
        <div class="mod1 flex-col" @click="choosePre()">
          <div class="group1 flex-col">
            <div class="section3 flex-col"><div class="bd1 flex-col"></div></div>
          </div>
        </div>
        <div  :class="{'mod2':activInde!=li,'mod3':activInde==li,'flex-col':true,pointer:true}"
            v-for="(li,index) in totalPage" :key="index"
            @click="choosePage(li)">
            <span :class="{'txt6':activInde!==li,'txt7':activInde==li,'flex-col':true}">{{li}}
            </span>
        </div>
        <!-- <div class="mod3 flex-col"><span class="txt7">2</span></div>
        <div class="mod3 flex-col "><span class="txt7">3</span></div>
        <div class="mod1 flex-col"><span class="info4">4</span></div>
        <div class="mod1 flex-col"><span class="word11">5</span></div>
       <div class="mod1 flex-col"><span class="word10">3</span></div>
        <div class="mod1 flex-col"><span class="info4">4</span></div>
        <div class="mod1 flex-col"><span class="word11">5</span></div> -->
        <div class="mod1 flex-col" @click="chooseNex()">
          <div class="wrap2 flex-col"><div class="section4 flex-col"></div></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import selects from '../../template/selects.vue'
export default {
  components:{selects},
  data() {
    return {
      isShow:false,
      data:null,
      defaultData:[],//页面展示的数据
      totalPage:0,//总共多少页
      activInde:1,//被激活的页码
      perPage:15//每页多少条数据
    };
  },
 created(){
    axios({
        url: "/get_issuereports"  //如果不指定method，默认发送get请求
    }).then(res => {
        this.data = res.data
       //页面展示的数据
       this.defaultData=this.data.slice(0,this.perPage)
       //总共多少也
       this.totalPage=Math.ceil((this.data.length++)/this.perPage)
        console.log(res)
    })
 },
 methods: {
     choosePage(id){
       //选择第几页
       this.activInde=id
       if(id==this.totalPage){
          this.defaultData=this.data.slice((this.activInde-1)*this.perPage,-1)

       }else{
          this.defaultData=this.data.slice((this.activInde-1)*this.perPage,(this.activInde-1)*this.perPage+this.perPage)
       }
         
       
     },
     choosePre(){
       //上一页
       if(this.activInde>=2){
          this.activInde--;
          this.defaultData=this.data.slice((this.activInde-1)*this.perPage,(this.activInde-1)*this.perPage+this.perPage)
       }
        
     },
     chooseNex(){
       //下一页
      this.activInde++;
      if(this.activInde<this.totalPage){
          this.defaultData=this.data.slice((this.activInde-1)*this.perPage,(this.activInde-1)*this.perPage+this.perPage)
       }else if(this.activInde== this.totalPage){
          this.defaultData=this.data.slice((this.activInde-1)*this.perPage,-1)

       }else{
         this.activInde--;
       }
        
     },
     toshow(){
       this.isShow=!this.isShow
    },
    toHomepage(){
      this.$router.push({path:'/homepage'})
    },
    toDiagnosis(){
      this.$router.push({path:'/diagnosis'})
    },
    toDependencyNetwork(){
      this.$router.push({path:'/dependencynetwork'})
    },
    toIssueReport(){
      this.$router.push({path:'/issuereports'})
    },
    toAbout(){
      this.$router.push({path:'/about'})
    }
  }
};
</script>

<style scoped lang="css" src="./assets/index.css" />