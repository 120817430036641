<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
import $ from 'jquery' //��������ʹ��
export default {
  data() {
    return {
    };
  },
  created(){
    $("body[class='main_bg']").attr('style',"zoom: "+$(window).width()/1945+";");
  },
  methods: {
   
  }
};
</script>

<style lang="css" src="./assets/common.css"/>
<style lang="css" src="./assets/index.css"/>
