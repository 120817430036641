<template>
  <div class="page flex-col " @click="toCloseAnyShow">
    <div class="box8 flex-col">
      <div class="section1 flex-col">
        <div class="mod15 flex-row">
          <img
            class="mod1"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng61ffb35a24cb841dce8f02fa2253f1315f3b29a94031ad9e9976da0a279290f2.png"
          />
          <span class="info1 pointer" @click="toHomepage">Homepage</span>
          <span class="word1 pointer" @click="toDiagnosis">Diagnosis</span>
<!--          <span class="txt1 pointer" @click="toDependencyNetwork">Dependency&nbsp;Network</span>-->
          <span class="word2 pointer" @click="toIssueReport">Issue&nbsp;reports</span>
          <span class="info2 pointer" @click="toAbout">About</span>
          <span class="txt1"></span><!--给 Dependency 占位用的-->
          <div class="mod4 flex-col">
            <img
              class="group1"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPnge978d5d56bd5fc93e61ea434f1d203fdc0e159e7885af3808d0af0703f8f3759.png"
            />
          </div>
        </div>
        <span class="info4 pointer" @click.stop="toshow">Reproduction&nbsp;package</span>
        <selects :isShow="isShow"></selects>
      </div>
      <div class="outer1 flex-col">
        <div class="wrap2 flex-row">
          <div class="animation flex-col"></div>
          <div class="wrap3 flex-col pointer" @click.stop="toDemoShow"></div>
          <span class="txt3">Archives</span>
          <div class="wrap4 flex-col justify-between">
            <span class="txt2">Upload&nbsp;Project&nbsp;Files</span>
            <div class="mod16 flex-row justify-between">
              <div class="block3 flex-col">
                <label>
                  <div class="box2 flex-row justify-between pointer">
                    <img
                      class="block4"
                      referrerpolicy="no-referrer"
                      src="./assets/img/SketchPng72d0d995074aad6240c8377fc8e42d4d056354bc1375a65b1f5c55abb063d901.png"
                    />
                    <span class="word3">
                      <input type="file"  accept=".whl" style="display:none;" @change="handleArchives($event)">
                      <span v-if="fileNameArchives == ''">Browse&nbsp;files</span>
                      {{fileNameArchives}}
                    </span>
                  </div>
                </label>
              </div>
              <span class="word4">Source&nbsp;Code&nbsp;Files</span>
            </div>
          </div>
          <div class="wrap5 flex-col justify-between">
            <img
              class="main1 pointer"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng9afee12c16bb8125e6cdb4cbcbfe03466d4cb7918efc904d6b7f02ea690e571b.png"
              @click.stop="toUploadShow"
            />
            <div class="block5 flex-col">
              <label>
                <div class="mod3 flex-row justify-between pointer">
                  <img
                    class="bd1"
                    referrerpolicy="no-referrer"
                    src="./assets/img/SketchPng72d0d995074aad6240c8377fc8e42d4d056354bc1375a65b1f5c55abb063d901.png"
                  />
                  <span class="word5">
                    <input type="file" accept=".gz,.bz2,.zip" style="display:none;" @change="handleSourceFiles($event)">
                    <span v-if="fileNameSourceFiles== ''">Browse&nbsp;files</span>
                    {{fileNameSourceFiles}}
                  </span>
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>
      <img
        class="mod5"
        referrerpolicy="no-referrer"
        src="./assets/img/SketchPnge84c45beb7e17db0cfcbc29a8124b2431b2b6261c838132e9ac2ad423d2bf62d.png"
      />
    </div>
    <div class="outer2 flex-col pointer"><span class="info3" @click="startLoading">Start</span></div>
    <div class="mod6 flex-col">
      <div class="group3 flex-col">
        <div class="block6 flex-row justify-between">
          <div class="group4 flex-col"></div>
          <span class="info5">Vulnerable&nbsp;C&nbsp;libraries&nbsp;Analysis</span>
        </div>
        <div class="block7 flex-col">
          <div class="box3 flex-row justify-between">
            <span class="word6">ID</span>
            <span class="word7">C&nbsp;library</span>
            <span class="word8">C&nbsp;Project&nbsp;Version</span>
            <span class="txt4">CVE&nbsp;List</span>
          </div>
        </div>

        <div v-for="(li,index) in defaultData" :key="index">
          <div class="block8 flex-col"></div>
          <div class="block9 flex-col" >
            <div class="bd2 flex-row justify-between">
              <span class="word9">{{ index }}</span>
              <span class="word10">{{ li.c_library }}</span>
              <span class="word11">{{ li.c_porject }}</span>
              <span class="info6">{{ li.cve_id }}</span>
            </div>
          </div>
        </div>
        <div class="block33 flex-col"></div>

        <div class="pmain38 flex-row justify-center" v-if="totalPage>1">
          <div class="pmod1 flex-col" @click="choosePre()">
            <div class="pgroup1 flex-col">
              <div class="psection3 flex-col"><div class="pbd1 flex-col"></div></div>
            </div>
          </div>
          <div  :class="{'pmod2':activInde!=li,'pmod3':activInde==li,'flex-col':true,pointer:true}"
                v-for="(li,index) in totalPage" :key="index"
                @click="choosePage(li)">
          <span :class="{'ptxt6':activInde!==li,'ptxt7':activInde==li,'flex-col':true}">{{li}}
          </span>
          </div>
          <div class="pmod1 flex-col" @click="chooseNex()">
            <div class="pwrap2 flex-col"><div class="psection4 flex-col"></div></div>
          </div>
        </div>

      </div>
    </div>
    <div class="mod10 flex-col">
      <div class="mod11 flex-row justify-between">
        <div class="group5 flex-col"></div>
        <span class="word33">
          Dependency&nbsp;Graph&nbsp;between&nbsp;Python/Java&nbsp;projects&nbsp;and&nbsp;Shared&nbsp;Libraries
        </span>
      </div>
      <!-- <div class="main2 flex-row"><div class="mod13 flex-col"></div></div> -->
      <!-- <div class="main3 flex-row"><span class="txt22">暂&nbsp;&nbsp;无&nbsp;&nbsp;数&nbsp;&nbsp;据</span></div> -->
      <!-- <div class="main3 flex-row"><span class="txt22"></span></div> -->
      <div class="mod_echart flex-row" id="graphs"></div>
    </div>
    <div class="mod14 flex-col">
      <div class="box4 flex-col">
        <div class="box5 flex-row justify-between">
          <div class="section3 flex-col"></div>
          <span class="txt23">Vulnerable&nbsp;C-APIs&nbsp;Analysis</span>
        </div>

        <div v-for="(li,index) in call_chain_data" :key="index">

          <div class="box6 flex-col">
            <span class="txt24">{{ index+1 }}. {{ li.cve_id }}, {{ li.cve_func }}</span>
          </div>
          <span class="paragraph1">
  <!--          call&nbsp;chain&nbsp;-----
            <br />  -->
            {{ li.call_chain }}
          </span>
        </div>
<!--        <div class="box6 flex-col">-->
<!--          <span class="txt24">1.&nbsp;CVE-2019-11068,&nbsp;xsltParseStylesheetImport()</span>-->
<!--        </div>-->
<!--        <span class="paragraph1">-->
<!--          call&nbsp;chain&nbsp;-&#45;&#45;&#45;&#45;-->
<!--          <br />-->
<!--          trMain()&nbsp;-&gt;&nbsp;xsltRun()&nbsp;-&gt;&nbsp;xsltLoadStylesheetPI()&nbsp;-&gt;&nbsp;xsltParseStylesheetDoc()&nbsp;-&gt;&nbsp;xsltParseStylesheetImportedDoc()&nbsp;-&gt;&nbsp;xsltParseStylesheetProcess()&nbsp;-&gt;&nbsp;xsltParseXSLTStylesheetElem()&nbsp;-&gt;&nbsp;xsltParseStylesheetImport()-->
<!--        </span>-->

<!--        <div class="box7 flex-col">-->
<!--          <span class="word34">2.&nbsp;CVE-2016-2073,&nbsp;htmlParseNameComplex()</span>-->
<!--        </div>-->
<!--        <span class="infoBox1">-->
<!--          call&nbsp;chain&nbsp;-&#45;&#45;&#45;&#45;-->
<!--          <br />-->
<!--          foMain()&nbsp;-&gt;&nbsp;foProcess()&nbsp;-&gt;&nbsp;htmlReadFile()&nbsp;-&gt;&nbsp;htmlDoRead()&nbsp;-&gt;&nbsp;htmlParseDocument()&nbsp;-&gt;&nbsp;htmlParsePI()&nbsp;-&gt;&nbsp;htmlParseName()&nbsp;-&gt;&nbsp;htmlParseNameComplex()-->
<!--        </span>-->

      </div>
    </div>
    <masklayer style="margin-top: 300px;"></masklayer>
<!--    <Loading :isShow='showLoading'></Loading>-->
    <Loading ref="loading" @endLoadingEvent="endLoadingEvent"></Loading>
    <selects :isShow="isShow" ></selects>
    <selects_upload :isShow="isUploadShow" ></selects_upload>
    <demo :isShow="isDemoShow" ></demo>
  </div>
</template>
<style scoped lang="css" src="./assets/index.css" />

<script>
import masklayer from '../../template/mask'
import Loading from '../../template/loading'
import selects from '../../template/selects.vue'
import selects_upload from '../../template/selects_upload.vue'
import demo from '../../template/demo.vue'
import * as echarts from "echarts"
import axios from 'axios'
export default {
  // components:{masklayer,selects},
  components:{selects, selects_upload, Loading,demo,masklayer},
  //components:{selects,Loading,demo},
  data() {
    return {
      cve_data: [
        // {
        //   "c_library":"libxslt-016dc083.so.1.1.26",
        //   "c_porject":"libxslt_1.1.29",
        //   "cve_id":"CVE-2019-11068",
        // },
        // {
        //   "c_library":"libxslt-016dc083.so.1.1.26",
        //   "c_porject":"libxslt_1.1.29",
        //   "cve_id":"CVE-2015-9019",
        // },
        // {
        //   "c_library":"libxslt-016dc083.so.1.1.26",
        //   "c_porject":"libxslt_1.1.29",
        //   "cve_id":"CVE-2019-11068",
        // },
        // {
        //   "c_library":"libxslt-016dc083.so.1.1.26",
        //   "c_porject":"libxslt_1.1.29",
        //   "cve_id":"CVE-2015-9019",
        // },
        // {
        //   "c_library":"libxslt-016dc083.so.1.1.26",
        //   "c_porject":"libxslt_1.1.29",
        //   "cve_id":"CVE-2019-11068",
        // },
        // {
        //   "c_library":"libxslt-016dc083.so.1.1.26",
        //   "c_porject":"libxslt_1.1.29",
        //   "cve_id":"CVE-2015-9019",
        // },
        // {
        //   "c_library":"libxslt-016dc083.so.1.1.26",
        //   "c_porject":"libxslt_1.1.29",
        //   "cve_id":"CVE-2019-11068",
        // },
        // {
        //   "c_library":"libxslt-016dc083.so.1.1.26",
        //   "c_porject":"libxslt_1.1.29",
        //   "cve_id":"CVE-2015-9019",
        // },
        // {
        //   "c_library":"libxslt-016dc083.so.1.1.26",
        //   "c_porject":"libxslt_1.1.29",
        //   "cve_id":"CVE-2019-11068",
        // },
        // {
        //   "c_library":"libxslt-016dc083.so.1.1.26",
        //   "c_porject":"libxslt_1.1.29",
        //   "cve_id":"CVE-2015-9019",
        // },
      ],
      constants: {},
      call_chain_data: [
        // {
        //   "cve_id":"CVE-2019-11068",
        //   "cve_func":"int xsltParseStylesheetImport()",
        //   "call_chain":"trMain() -> xsltRun() -> xsltLoadStylesheetPI() -> xsltParseStylesheetDoc() -> xsltParseStylesheetImportedDoc() -> xsltParseStylesheetProcess() -> xsltParseXSLTStylesheetElem() -> xsltParseStylesheetImport",
        // },
        // {
        //   "cve_id":"CVE-2017-5029",
        //   "cve_func":"xmlNodePtr xsltAddTextString()",
        //   "call_chain":"selMain() -> xsltInitLibXml() -> exsltRegisterAll() -> exsltCommonRegister() -> xsltDocumentComp() -> xsltNewStylePreComp() -> xsltCopy() -> xsltCopyText() -> xsltAddTextString()",
        // },
        // {
        //   "cve_id":"CVE-2016-2073",
        //   "cve_func":"htmlParseNameComplex",
        //   "call_chain":"foMain() -> foProcess() -> htmlReadFile() -> htmlDoRead() -> htmlParseDocument() -> htmlParsePI() -> htmlParseName() -> htmlParseNameComplex()",
        // },
      ],
      isShow:false,
      isUploadShow:false,
      isDemoShow:false,
      categories: [],
      types: ['C Library', 'Vulnerable C Library', 'Python/Java Package'],
      datas: [],
      links: [],
      fileNameArchives:'',
      fileNameSourceFiles:'',
      fileArchives:'',
      fileSourceFiles:'',
      defaultData:[],//页面展示的数据
      totalPage:0,//总共多少页
      activInde:1,//被激活的页码
      perPage:10//每页多少条数据
    };
  },
  created(){
    // django 使用 csrf 验证，需要在 POST 等请求中附加 csrf_token
    // vue 无法渲染 {% csrf_token %}，因此使用 axios get 方法在 create 时先获取并设置到 cookie 中。
    axios.get('/get_csrf').then(res => {
      let csrf_token = res.data.token
      window.sessionStorage.setItem("csrf_token", csrf_token)
    })
    // //页面展示的数据
    // this.defaultData=this.cve_data.slice(0,this.perPage)
    // //总共多少也
    // this.totalPage=Math.ceil((this.cve_data.length++)/this.perPage)
    // console.log("page")
    // console.log(this.totalPage)
  },

  methods: {
    get_diagnosis_graph(){
      // $("body[class='main_bg']").attr('style',"zoom: "+$(window).width()/1945+";");
      axios({
        //url: "http://insight-clv-detection.com/get_diagnosis_graph"  //如果不指定method，默认发送get请求
        url: "/get_diagnosis_graph"  //如果不指定method，默认发送get请求
      }).then(res => {
        this.datas = res.data['nodes']
        this.links = res.data['edges']
        this.montheahcrt()
        console.log(res)
      })
    },
    get_cve_data(){
      axios({
        //url: "http://insight-clv-detection.com/get_cve_data"  //如果不指定method，默认发送get请求
        url: "/get_cve_data"  //如果不指定method，默认发送get请求
      }).then(res => {
        this.cve_data = res.data
        //页面展示的数据
        this.defaultData=this.cve_data.slice(0,this.perPage)
        //总共多少也
        this.totalPage=Math.ceil((this.cve_data.length++)/this.perPage)
        console.log(res)
      })
    },
    get_call_chain(){
      axios({
        //url: "http://insight-clv-detection.com/get_call_chain"  //如果不指定method，默认发送get请求
        url: "/get_call_chain"  //如果不指定method，默认发送get请求
      }).then(res => {
        this.call_chain_data = res.data
        console.log(res)
      })
    },
    choosePage(id){
      //选择第几页
      this.activInde=id
      if(id==this.totalPage){
        this.defaultData=this.cve_data.slice((this.activInde-1)*this.perPage,-1)

      }else{
        this.defaultData=this.cve_data.slice((this.activInde-1)*this.perPage,(this.activInde-1)*this.perPage+this.perPage)
      }


    },
    choosePre(){
      //上一页
      if(this.activInde>=2){
        this.activInde--;
        this.defaultData=this.cve_data.slice((this.activInde-1)*this.perPage,(this.activInde-1)*this.perPage+this.perPage)
      }

    },
    chooseNex(){
      //下一页
      this.activInde++;
      if(this.activInde<this.totalPage){
        this.defaultData=this.cve_data.slice((this.activInde-1)*this.perPage,(this.activInde-1)*this.perPage+this.perPage)
      }else if(this.activInde== this.totalPage){
        this.defaultData=this.cve_data.slice((this.activInde-1)*this.perPage,-1)

      }else{
        this.activInde--;
      }

    },
    toCloseAnyShow(){
      this.isDemoShow=false
      this.isShow=false
      this.isUploadShow=false
    },
    toDemoShow(){
      this.isShow=false
      this.isUploadShow=false
      this.isDemoShow=!this.isDemoShow
    },
    toshow(){
      this.isDemoShow=false
      this.isShow=!this.isShow
      console.log(1);
    },
    toUploadShow(){
      this.isDemoShow=false
      this.isShow=false
      this.isUploadShow=!this.isUploadShow
      //console.log(1);
    },
    toHomepage(){
      this.$router.push({path:'/homepage'})
    },
    toDiagnosis(){
      this.$router.push({path:'/diagnosis'})
    },
    toDependencyNetwork(){
      this.$router.push({path:'/dependencynetwork'})
    },
    toIssueReport(){
      this.$router.push({path:'/issuereports'})
    },
    toAbout(){
      this.$router.push({path:'/about'})
    },
    // 选择文件上传
    handleArchives (file) {
      this.fileArchives = file.target.files[0]
      this.fileNameArchives = file.target.files[0].name
      console.log("selected Archives files:")
      console.log(this.fileNameArchives)
    },
    // 选择文件上传
    handleSourceFiles (file) {
      this.fileSourceFiles = file.target.files[0]
      this.fileNameSourceFiles = file.target.files[0].name
      console.log("selected Archives files:")
      console.log(this.fileNameSourceFiles)
    },
    startLoading(){
      let formData = new FormData();
      formData.append('archive', this.fileArchives)
      formData.append('source', this.fileSourceFiles)

      // django 使用 csrf 验证，需要在 POST 等请求中附加 csrf_token
      // vue 无法渲染 {% csrf_token %}，因此使用 axios get 方法在 create 时先获取并设置到 cookie 中。
      // 在 post 时读取并设置到 headers 中
      console.log("token " + window.sessionStorage.getItem("csrf_token"))
      const instance = axios.create({
        headers:{
          'Content-Type':'multipart/form-data',
          'X-CSRFToken':window.sessionStorage.getItem("csrf_token")
        }
      })
      console.log("post")
      this.$refs.loading.isShow = true
      instance.post("/upload", formData).then(res=>{
        alert(res.data.msg);
        if (res.data.code == 200){
          this.$refs.loading.isShow = true
          this.$refs.loading.tipsShow = true;
          this.$refs.loading.showTips()
        }
        else{
          this.$refs.loading.isShow = false;
        }
      })

    },
    endLoadingEvent(){
      console.log("endLoadingEvent")
      this.$refs.loading.isShow = false
      this.$refs.loading.tipsShow = false;
      this.get_diagnosis_graph()
      this.get_cve_data()
      this.get_call_chain()
    },
    montheahcrt () {
      var myChart = echarts.init(document.getElementById('graphs'))

      for (var i = 0; i < this.types.length; i++) {
        this.categories[i] = {
          name: this.types[i]
        }
      }
      var option = {
        animationDurationUpdate: 1500,//数据更新动画的时长。
        animationEasingUpdate: 'quinticInOut',//数据更新动画的缓动效果。
        color: ['#008000', '#c00', '#cc6633', '#ccc'],
        title: {
          text: ' ', // 正标题
          bottom: '20px', // 上下位置
          left: '20px', // 左右位置
          textStyle: { // 图例文字的样式
            fontSize: 12,
            fontWeight: 550,
            color: '#666'
          }
        },
        grid: {
          top: 0,
          bottom: 0,
          left: 0,
          right: 0
        },
        legend: [{
          // selectedMode: 'single',
          // 图例位置
          bottom: '20px',
          left: '120px',
          textStyle: { // 图例文字的样式
            fontSize: 16,
            fontWeight: 550,
            color: '#666'
          },
          icon: 'circle', //  'circle', 'rect', 'roundRect', 'triangle', 'diamond', 'pin', 'arrow'
          itemGap: 35, // 图例之间的间距
          data: this.categories.map(function (a) {
            return a.name
          })
        }],

        series: [{
          type: 'graph', // 类型:关系图
          layout: 'force', // 图的布局，类型为力导图
          focusNodeAdjacency: true, // 当鼠标移动到节点上，突出显示节点以及节点的边和邻接节点
          draggable: true, // 指示节点是否可以拖动
          symbolSize: 23, // 调整节点的大小
          roam: 'scale', // 是否开启鼠标缩放和平移漫游。默认不开启。如果只想要开启缩放或者平移,可以设置成 'scale' 或者 'move'。设置成 true 为都开启
          edgeSymbol: ['circle', 'arrow'], // 边两端的标记类型，可以是一个数组分别指定两端，也可以是单个统一指定。默认不显示标记，常见的可以设置为箭头，如下：edgeSymbol: ['circle', 'arrow']
          edgeSymbolSize: [2, 10], // 边两端的标记大小，可以是一个数组分别指定两端，也可以是单个统一指定。
          edgeLabel: {
            normal: {
              show: false,
              formatter: function (x) {
                return x.data.name
              },
              textStyle: {
                fontSize: 14,
                color: '#666666'
              }
            }
          },
          force: { //力引导图基本配置
            layoutAnimation:true,
            // xAxisIndex : 0, //x轴坐标 有多种坐标系轴坐标选项
            // yAxisIndex : 0, //y轴坐标
            gravity:0.01, //节点受到的向中心的引力因子。该值越大节点越往中心点靠拢。
            edgeLength: 30, //边的两个节点之间的距离，这个距离也会受 repulsion。[10, 50] 。值越小则长度越长
            repulsion: 900 //节点之间的斥力因子。支持数组表达斥力范围，值越大斥力越大。
            // repulsion: 3000, //节点之间的斥力因子。支持数组表达斥力范围，值越大斥力越大。
            // edgeLength: 80 //边的两个节点之间的距离，这个距离也会受 repulsion。[10, 50] 。值越小则长度越长
          },
          lineStyle: {
            normal: {
              width: 1,
              // color: '#4b565b',
              color: 'target', // 决定边的颜色是与起点相同还是与终点相同
              curveness: 0.1,
              type: 'dashed' // 'dotted'点型虚线 'solid'实线 'dashed'线性虚线
            }
          },
          label: {
            show: true,
            position: 'top',
            formatter: '{b}',
            textStyle: {
              fontSize: 16,
              fontWeight: 600,
              color: '#666'
            }
          },
          // 数据
          data: this.datas,

          links: this.links,
          categories: this.categories
        }]
      }
      myChart.setOption(option)
      // 让图表跟随屏幕自动的去适应
      window.addEventListener('resize', function () {
        myChart.resize()
      })
    }
  }
};
</script>
