<template>
  <div class="page flex-col">
    <div class="layer1 flex-col">
      <div class="main1 flex-col">
        <div class="layer2 flex-row">
          <img
            class="img1"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng61ffb35a24cb841dce8f02fa2253f1315f3b29a94031ad9e9976da0a279290f2.png"
          />
          <span class="txt1 pointer" @click="toHomepage">Homepage</span>
          <span class="word1 pointer" @click="toDiagnosis">Diagnosis</span>
<!--          <span class="word2 pointer" @click="toDependencyNetwork">Dependency&nbsp;Network</span>-->
          <span class="info1 pointer" @click="toIssueReport">Issue&nbsp;reports</span>
          <span class="word3 pointer" @click="toAbout">About</span>
          <span class="word2"></span><!--给 Dependency 占位用的-->
          <div class="layer3 flex-col">
            <img
              class="icon1"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPnge978d5d56bd5fc93e61ea434f1d203fdc0e159e7885af3808d0af0703f8f3759.png"
            />
          </div>
        </div>
        <span class="word4 pointer">Reproduction&nbsp;package</span>
      </div>
      <div class="main2 flex-col">
        <div class="layer16 flex-row">
          <div class="mod1 flex-col"></div>
          <span class="info3">Archives</span>
          <div class="mod2 flex-col justify-between">
            <span class="info2">Upload&nbsp;Project&nbsp;Files</span>
            <div class="group3 flex-row justify-between">
              <div class="group1 flex-col">
                <div class="main3 flex-row justify-between">
                  <img
                    class="label1"
                    referrerpolicy="no-referrer"
                    src="./assets/img/SketchPng72d0d995074aad6240c8377fc8e42d4d056354bc1375a65b1f5c55abb063d901.png"
                  />
                  <span class="info4">Browse&nbsp;files</span>
                </div>
              </div>
              <span class="word5">Source&nbsp;Code&nbsp;Files</span>
            </div>
          </div>
          <div class="mod3 flex-col justify-between">
            <img
              class="icon2"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng9afee12c16bb8125e6cdb4cbcbfe03466d4cb7918efc904d6b7f02ea690e571b.png"
            />
            <div class="group2 flex-col">
              <div class="block1 flex-row justify-between">
                <img
                  class="label2"
                  referrerpolicy="no-referrer"
                  src="./assets/img/SketchPng72d0d995074aad6240c8377fc8e42d4d056354bc1375a65b1f5c55abb063d901.png"
                />
                <span class="word6">Browse&nbsp;files</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <img
        class="img2"
        referrerpolicy="no-referrer"
        src="./assets/img/SketchPnge84c45beb7e17db0cfcbc29a8124b2431b2b6261c838132e9ac2ad423d2bf62d.png"
      />
    </div>
    <div class="layer6 flex-col"><span class="word7">Start</span></div>
    <div class="layer7 flex-col">
      <div class="layer8 flex-col">
        <div class="layer9 flex-row justify-between">
          <div class="layer10 flex-col"></div>
          <span class="word8">Vulnerable&nbsp;C&nbsp;libraries&nbsp;Analysis</span>
        </div>
        <div class="layer11 flex-col"></div>
        <span class="word9">Detection&nbsp;failed</span>
        <div class="layer12 flex-col">
          <span class="paragraph1">
            1.Can&nbsp;not&nbsp;trace&nbsp;the&nbsp;provenance&nbsp;of&nbsp;the&nbsp;C&nbsp;libraries.&nbsp;
            <br />
            2.No&nbsp;vulnerabilities&nbsp;in&nbsp;the&nbsp;C&nbsp;libraries.
          </span>
        </div>
        <div class="layer13 flex-col"></div>
      </div>
    </div>
    <div class="layer14 flex-col">
      <div class="main4 flex-row justify-between">
        <div class="section1 flex-col"></div>
        <span class="word10">
          Dependency&nbsp;Graph&nbsp;between&nbsp;Python/Java&nbsp;projects&nbsp;and&nbsp;Shared&nbsp;Libraries
        </span>
      </div>
      <div class="main5 flex-row"><div class="box1 flex-col"></div></div>
      <div class="main6 flex-row"><span class="word11">暂&nbsp;&nbsp;无&nbsp;&nbsp;数&nbsp;&nbsp;据</span></div>
    </div>
    <div class="layer15 flex-col">
      <div class="box2 flex-row justify-between">
        <div class="main7 flex-col"></div>
        <span class="info5">Vulnerable&nbsp;C-APIs&nbsp;Analysis</span>
      </div>
      <div class="box3 flex-row"><div class="main8 flex-col"></div></div>
      <div class="box4 flex-row"><span class="txt2">Detection&nbsp;failed</span></div>
      <div class="box5 flex-row">
        <div class="outer1 flex-col">
          <span class="paragraph2">
            1.Can&nbsp;not&nbsp;identify&nbsp;the&nbsp;FFI&nbsp;type.
            <br />
            2.No&nbsp;vulnerable&nbsp;C-APIs&nbsp;can&nbsp;be&nbsp;invoked.
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      constants: {}
    };
  },
  methods: {
    toHomepage(){
      this.$router.push({path:'/homepage'})
    },
    toDiagnosis(){
      this.$router.push({path:'/diagnosis'})
    },
    toDependencyNetwork(){
      this.$router.push({path:'/dependencynetwork'})
    },
    toIssueReport(){
      this.$router.push({path:'/issuereports'})
    },
    toAbout(){
      this.$router.push({path:'/about'})
    }
  }
};
</script>
<style scoped lang="css" src="./assets/index.css" />