<template>
   <transition name="fade">
        <div class="reproduction_upload" v-if="isShow"  style="color: white; float: right; line-height: 1.5; font-family: &quot;PingFangSC-Medium&quot;; display: block;">
             <span style="font-weight: bold;font-size: 16px">Example Projects:</span><br>

            <a href="/static/xmlstarlet-1.6.7.zip" style="color: rgb(255, 255, 255);
            text-decoration: none;" onmouseover="this.style.color='#FCFFB3';" onmouseout="this.style.color='#FFFFFF';">
              xmlstarlet-1.6.7</a><br>
            <a href="/static/archi-0.2.3.zip" style="color: rgb(255, 255, 255);
            text-decoration: none;" onmouseover="this.style.color='#FCFFB3';" onmouseout="this.style.color='#FFFFFF';">
              archi-0.2.3</a><br>
        </div>
   </transition>
</template>
<script>
export default {
  data() {
    return {
    };
  },
  props:{
    isShow:Boolean
  },
  methods: {
    
  }
};
</script>
<style>
  .reproduction_upload {
    display: none;
     margin-right: 5px;
     width: 10%;
     border-radius: 10px;
     padding: 10px;
     position: absolute;
     right: 600px;
     top: 70px;
     background-color: #2F4056;
     float: right;
     z-index: 1001;
 }
 .fade-enter-active, .fade-leave-active {
   transition: opacity .5s;
 }
 .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
   opacity: 0;
 }
</style>