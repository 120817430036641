<template>
  <div class="page flex-col">
    <div class="wrap2 flex-col">
      <div class="main4 flex-row">
        <div class="layer2 flex-col"></div>
        <div class="box1 flex-col pointer" @click="toDiagnosis"><span class="txt3">Diagnosis</span></div>
        <div class="box2 flex-col pointer" @click="toAbout"><span class="info2">About&nbsp;Insight</span></div>
      </div>
      <div class="main5 flex-row">
        <div class="mod1 flex-col">
          <img
            class="img2"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng2721e320ba73a744b51909e908a67ccc0554b99aad016c1d0aabb0c1fc92bfbc.png"
          />
        </div>
      </div>
      <div class="main1 flex-col">
        <div class="group1 flex-row">
          <img
            class="pic1"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng50b6505eaa09dd95359e68dac63d59bc6d8c601634395b14eb6f3adfae56f704.png"
          />
          <span class="txt1 pointer" @click="toHomepage">Homepage</span>
          <span class="word1 pointer" @click="toDiagnosis">Diagnosis</span>
<!--          <span class="word2 pointer" @click="toDependencyNetwork">Dependency&nbsp;Network</span>-->
          <span class="info1 pointer" @click="toIssueReport">Issue&nbsp;reports</span>
          <span class="word3 pointer" @click="toAbout">About</span>
          <span class="word2"></span> <!--给 Dependency 占位用的-->

          <div class="group2 flex-col">
            <img
              class="icon1"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPnge67008ad32ef48f8a7fe730ad625e5f57382ab5acfb498053fda84cad008a7f9.png"
            />
          </div>
        </div>
        <span class="word4 pointer" @click="toshow">Reproduction&nbsp;package</span>
       <!-- <transition name="fade">
        <div class="reproduction" v-if="isShow"  style="color: white; float: right; line-height: 1.5; font-family: &quot;Palatino Linotype&quot;; display: block;">
             <span style="font-weight: bold;font-size: 20px">DownLoad:</span><br>
            <a href="https://repo1.maven.org/maven2/junit/junit/4.13.2/junit-4.13.2.jar" style="color: rgb(255, 255, 255); text-decoration: none;" onmouseover="this.style.color='#FCFFB3';" onmouseout="this.style.color='#FFFFFF';">1.&nbsp;20k Subjects Info In RQ1</a><br>
            <a href="https://repo1.maven.org/maven2/junit/junit/4.13.2/junit-4.13.2.jar" style="color: rgb(255, 255, 255); text-decoration: none;" onmouseover="this.style.color='#FCFFB3';" onmouseout="this.style.color='#FFFFFF';">2.&nbsp;Benchmark Dataset (132 DM issues and subjects <br>&nbsp;&nbsp;&nbsp;&nbsp;used for evaluation in RQ4)</a><br>
            <a href="https://repo1.maven.org/maven2/junit/junit/4.13.2/junit-4.13.2.jar" style="color: rgb(255, 255, 255); text-decoration: none;" onmouseover="this.style.color='#FCFFB3';" onmouseout="this.style.color='#FFFFFF';">3.&nbsp;Empirical Study Dataset (151 DM issues for <br>&nbsp;&nbsp;&nbsp;&nbsp;RQ2&amp;3)</a>
        </div>
        </transition> -->
        <selects :isShow="isShow"></selects>
      </div>
     
      <div class="main6 flex-col">
        <div class="box3 flex-col justify-between">
          <div class="img1 flex-col"></div>
          <span class="txt2">Exploring&nbsp;Cross-Ecosystem&nbsp;Vulnerability&nbsp;Impacts</span>
        </div>
      </div>
      <div class="main7 flex-col"></div>
    </div>
  </div>
</template>
<script>
import selects from '../../template/selects.vue'
export default {
  components:{selects},
  data() {
    return {
      constants: {},
      isShow:false
    };
  },
  methods: {
    toshow(){
      this.isShow=!this.isShow
      console.log(1);
    },
    toHomepage(){
      this.$router.push({path:'/homepage'})
    },
    toDiagnosis(){
      this.$router.push({path:'/diagnosis'})
    },
    toDependencyNetwork(){
      this.$router.push({path:'/dependencynetwork'})
    },
    toIssueReport(){
      this.$router.push({path:'/issuereports'})
    },
    toAbout(){
      this.$router.push({path:'/about'})
    }
  }
};
</script>
<style scoped lang="css" src="./assets/index.css" />